@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
.standard-button {
  @apply hover:bg-primary hover:text-secondary transition inline-block px-8 py-3 my-4 rounded-xl bg-secondary text-white font-sans text-lg
};
  #nav-primary {
    a.link {
      @apply flex items-center h-full transition p-3 xl:px-4 text-white py-6 font-sans text-lg xl:text-xl
    }

    a.link:hover {
      @apply bg-white bg-opacity-20
    }

    a.active {
      box-shadow: 0 -3px 0 0 theme('colors.white') inset;
      @apply text-white bg-white
    }

    li {
      @apply h-full items-center
    }
  }
}

  .mm-menu {
    --mm-color-background: theme('colors.primary.DEFAULT');
  }

  .header-clip {
    @screen lg {
      clip-path: ellipse(50% 175% at 0% 00%) !important;
    }

  }

#mobile-nav:not(.mm-menu) {
  display: none;
}